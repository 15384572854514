import { withStores } from '@nanostores/lit';
import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/input/input.js';
import {
	LitElement,
	css,
	html,
	nothing,
} from 'lit';
import { customElement } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';

import { $i18n } from '../../store/i18n';
import {
	$errorClient,
	$errorServer,
	$handleInputEmail,
	$handleInputPassword,
	$handleSubmit,
	$login,
	$state,
} from '../../store/login';

const $t = $i18n('__I18N_PATH__', {
	emailLabel: 'E-mail adress',
	emailPlaceholder: 'Your e-mail address',
	passwordLabel: 'Password',
	passwordPlaceholder: 'Your password',
	submitLabel: 'Log in',
});

@customElement('login-form')
export class LoginForm extends withStores(LitElement, [
	$errorClient,
	$errorServer,
	$login,
	$state,
	$t,
]) {
	public static styles = [css`/* @unocss-placeholder */`];

	async #handleSubmit($event: SubmitEvent) {
		$event.preventDefault();

		await $handleSubmit();
	}

	// eslint-disable-next-line max-lines-per-function
	protected render() {
		return html`
			<form
				class="grid gap-y-4"
				@submit=${this.#handleSubmit}
			>
				<sl-input
					.value=${$login.get().email}
					@sl-input=${($event: Event) => { $handleInputEmail(($event.composedPath()[0] as HTMLInputElement).value); }}
					autocapitalize="off"
					autocomplete="username"
					autocorrect="off"
					class="samsam-input"
					id="email"
					label=${$t.get().emailLabel}
					name="email"
					placeholder=${$t.get().emailPlaceholder}
					required
					type="text"
				>
					${($errorClient.get().email ?? []).length === 0 ? nothing : html`
						<div slot="help-text" class="grid gap-y-2">
							${repeat($errorClient.get().email ?? [], message => message, message => html`
								<span class="text-red-4 dark:text-red-3">${message}</span>
							`)}
						</div>
					`}
				</sl-input>
				<sl-input
					.value=${$login.get().password}
					@sl-input=${($event: Event) => { $handleInputPassword(($event.composedPath()[0] as HTMLInputElement).value); }}
					autocapitalize="off"
					autocomplete="current-password"
					autocorrect="off"
					class="samsam-input"
					id="password"
					label=${$t.get().passwordLabel}
					pill
					name="password"
					placeholder=${$t.get().passwordPlaceholder}
					password-toggle
					required
					type="password"
				>
					${($errorClient.get().password ?? []).length === 0 ? nothing : html`
						<div slot="help-text" class="grid gap-y-2">
							${repeat($errorClient.get().password ?? [], message => message, message => html`
								<span class="text-red-4 dark:text-red-3">${message}</span>
							`)}
						</div>
					`}
				</sl-input>
				${$errorServer.get() === null ? nothing : html`
					<div class="grid justify-end">
						<span class="text-red-4 dark:text-red-3">${$errorServer.get()}</span>
					</div>
				`}
				<sl-button class="justify-self-end samsam-button" type="submit" ?loading=${$state.get().loading}>
					<svg slot="prefix" width="1.5em" height="1.5em"><use href="/sprite.svg#lock-key-open"/></svg>
					${$t.get().submitLabel}
				</sl-button>
			</form>
		`;
	}
}

declare global {
  interface HTMLElementTagNameMap /* eslint-disable-line @typescript-eslint/consistent-type-definitions */ {
    'login-form' /* eslint-disable-line @typescript-eslint/naming-convention */: LoginForm;
  }
}
