import type { AuthError } from '@supabase/supabase-js';

import { atom, map } from 'nanostores';
import {
	type InferOutput,
	boolean,
	email,
	flatten,
	getDefaults,
	minLength,
	object,
	optional,
	pipe,
	safeParse,
	string,
	trim,
} from 'valibot';

import { $authBrowser } from '../api';
import { $i18n } from './i18n';

const $t = $i18n('__I18N_PATH__', {
	emailMinLength: 'Please enter your email.',
	emailValid: 'This is not a valid e-mailaddress',
	password: 'Please enter your password.',
});

export const $loginSchema = object({
	email: optional(pipe(string(), trim(), minLength(1, $t.get().emailMinLength), email($t.get().emailValid)), ''),
	password: optional(pipe(string(), minLength(1, $t.get().password)), ''),
});

export const $stateSchema = object({ loading: optional(boolean(), false) });

export const $login = map<InferOutput<typeof $loginSchema>>(getDefaults($loginSchema));
export const $state = map<InferOutput<typeof $stateSchema>>(getDefaults($stateSchema));

export const $errorClient = map<NonNullable<ReturnType<typeof flatten<typeof $loginSchema>>['nested']>>();
export const $errorServer = atom<AuthError['message'] | null>(null);

export const $handleErrorClientReset = (key: keyof typeof $loginSchema.entries) => {
	$errorClient.set({
		...$errorClient.get(),
		[key]: [],
	});
};
export const $handleErrorServerReset = () => {
	$errorServer.set(null);
};

export const $handleInputEmail = (value: string) => {
	$handleErrorClientReset('email');
	$handleErrorServerReset();
	$login.setKey('email', value);
};

export const $handleInputPassword = (value: string) => {
	$handleErrorClientReset('password');
	$handleErrorServerReset();
	$login.setKey('password', value);
};

// eslint-disable-next-line max-statements
export const $handleSubmit = async () => {
	const validated = safeParse($loginSchema, $login.get(), { abortPipeEarly: true });

	if (!validated.success) {
		$errorClient.set(flatten<typeof $loginSchema>(validated.issues).nested ?? {});

		return;
	}

	$state.set({ loading: true });

	const { error } = await $authBrowser.signInWithPassword(validated.output);

	$state.set({ loading: false });

	if (error !== null) {
		$errorServer.set(error.message);

		return;
	}

	const parameters = new URLSearchParams(window.location.search);

	const redirectTo = parameters.get('redirectTo') ?? '/';

	window.location.href = redirectTo;
};

